import { Text } from 'components';
import AppleStoreButton from 'pages/start/success/components/AppleStoreButton';
import GooglePlayButton from 'pages/start/success/components/GooglePlayButton';
import React, { FC } from 'react';
import styled from 'styled-components';
import { tablet } from 'styles/breakpoints';
import Lottie from 'lottie-react';
import success from 'assets/lotties/success.json';
import { AppState } from 'state/types';
import { useSelector } from 'react-redux';

interface RegisterCompletedProps {
  successTitle?: string;
  successInfo?: string;
}

const Container = styled.div`
  border-top: 1px solid #f0f0f0;
  @media ${tablet} {
  }
`;

const Title = styled(Text)`
  color: #000;
  text-align: center;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: -0.028rem;
  padding-bottom: 0.75rem;
  @media ${tablet} {
    font-size: 1.5rem;
    letter-spacing: -0.036rem;
  }
`;

const Label = styled(Text)`
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
  padding-bottom: 2.375rem;
  text-align: center;
  span {
    font-weight: 700;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  gap: 1rem;
`;

const SvgContainer = styled.div`
  width: 8.75rem;
  height: 8.75rem;
  margin-bottom: 0.75rem;
  svg {
    width: 8.75rem;
    height: 8.75rem;
  }
`;
const AppleStoreButtonStyled = styled(AppleStoreButton)`
  -webkit-tap-highlight-color: transparent;
`;
const GooglePlayButtonStyled = styled(GooglePlayButton)`
  -webkit-tap-highlight-color: transparent;
`;

const RegisterCompleted: FC<RegisterCompletedProps> = ({
  successTitle,
  successInfo,
}) => {
  const { user } = useSelector((state: AppState) => state.user);
  return (
    <Container>
      <Inner>
        <SvgContainer>
          <Lottie animationData={success} loop={false} />
        </SvgContainer>
        <Title>
          {successTitle
            ? successTitle
            : 'You have successfully created your account!'}
        </Title>
        <Label
          dangerouslySetInnerHTML={{
            __html: successInfo
              ? successInfo.replace('${email}', user?.email)
              : 'Download the Welcome Baby app on your phone using the buttons below! Use your email <span>${email}</span> and created password to log in.'.replace(
                  '${email}',
                  user?.email,
                ),
          }}
        />
        <ButtonContainer>
          <AppleStoreButtonStyled />
          <GooglePlayButtonStyled />
        </ButtonContainer>
      </Inner>
    </Container>
  );
};

export default RegisterCompleted;

const Inner = styled.section`
  max-width: 30.75rem;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 1rem;
`;
