import React, { FC } from 'react';
import styled from 'styled-components';

import BannerOne from '../BannerOne';
import { mobile, smMobile, tablet, useQuery } from 'styles/breakpoints';
import PaymentSteps from 'pages/start/payments/components/PaymentSteps';
import Headline from '../Headline';
import Button from '../Button';
import CancelButton from '../CancelButton';
import { ImageWithContainer } from '../..';
import List from '../List';
import ImageSection from 'pages/start/checkout/components/ImageSection';
import { DynamicImage } from 'components';
import UpsellReviewSection from '../UpsellReviewSection';

interface AppSubscriptionProps {
  subtitleAlt?: string;
  listPadding?: string;
  listPaddingMobile?: string;
}

const PaymentStepsStyled = styled(PaymentSteps)`
  display: flex;
  justify-content: center;
  padding: 1.5rem 1rem;
  width: 100%;
  background-color: #f0f0f0;
`;

const CancelButtonStyled = styled(CancelButton)`
  margin: 0.75rem 0 3rem 0;
`;

const ListStyled = styled(List)<{
  backgroundColor?: string;
  containerPadding?: string;
  containerPaddingMobile?: string;
}>`
  /* padding-top: 2rem !important;
  padding-bottom: 3rem !important; */

  padding: ${({ containerPadding }) =>
    containerPadding ? containerPadding : '2rem 0 3rem'} !important;
  ${({ backgroundColor }) =>
    backgroundColor && `background-color: ${backgroundColor};`}

  @media ${tablet} {
    /* padding-top: 0.5rem !important;
    padding-bottom: 1rem !important; */

    padding: ${({ containerPaddingMobile }) =>
      containerPaddingMobile
        ? containerPaddingMobile
        : '0.5rem 0 1rem'} !important;
  }
`;

const TopTextContainer = styled.div`
  background-color: #f0f0f0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem 1rem 2.25rem;
  @media ${tablet} {
    padding: 1rem;
  }
`;

const HeadlineAlt = styled.p`
  color: #1c1c28;
  text-align: center;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 2.8rem */
  max-width: 35rem;
  @media ${tablet} {
    max-width: 30.5rem;
    font-size: 1.5rem;
    line-height: 2rem;
  }
`;

const Label = styled.p`
  max-width: 35rem;
  color: #28293d;
  font-size: 1.125rem;
  padding: 0.75rem 0 2.25rem;
  @media ${tablet} {
    font-size: 1rem;
    line-height: 1.4375rem;
    padding: 1rem 1rem 4rem;
  }
`;

const DynamicImageStyled = styled(DynamicImage)`
  max-width: 30.5rem;
  width: 100%;
  height: 100%;
`;

const ImgContainer = styled.div`
  background-color: #f0f0f0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1rem;
`;
const DynamicImageStyledBottom = styled(DynamicImage)`
  max-width: 21.4rem;
  width: 100%;
  height: 100%;
`;

const ImgContainerBottom = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1rem;
`;

const LabelStyled = styled(Label)`
  max-width: 30.5rem;
`;

const HeadlineStyled = styled.p`
  color: #000;
  text-align: center;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.375rem;
  @media ${tablet} {
    font-size: 1.5rem;
    line-height: 1.8125rem;
  }
`;

const SubtitleAlt = styled.p`
  color: #000;
  text-align: center;
  font-family: 'Manrope';
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 0.5rem;

  b {
    font-weight: 700;
  }
`;

const SecondSubTextContainer = styled.div<{ backgroundColor?: string }>`
  ${({ backgroundColor }) =>
    backgroundColor && `background-color: ${backgroundColor};`}
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AppSubscription: FC<AppSubscriptionProps> = ({
  paymentSteps,
  headlineAlt,
  list,
  planSection,
  PlanSectionComponent,
  headline2,
  headline3,
  label,
  planSection2,
  reviewsSection,
  userHasBoughtPaperbackBook,
  subtitleAlt,
  listPadding,
  listPaddingMobile,
}) => {
  const { isTablet } = useQuery();
  return (
    <>
      <PaymentStepsStyled
        {...paymentSteps}
        hasOptionalStep={userHasBoughtPaperbackBook}
      />

      <TopTextContainer>
        <HeadlineAlt>{headlineAlt}</HeadlineAlt>
        {subtitleAlt && (
          <SubtitleAlt dangerouslySetInnerHTML={{ __html: subtitleAlt }} />
        )}
      </TopTextContainer>
      <ImgContainer>
        <DynamicImageStyled src="upsell/app.png" alt="Welcome baby app" />
      </ImgContainer>
      <PlanSectionComponent {...planSection} />
      <ListStyled
        {...list}
        backgroundColor={list.listContainerBg}
        containerPadding={listPadding}
        containerPaddingMobile={listPaddingMobile}
      />
      <SecondSubTextContainer backgroundColor={planSection2.bgColor}>
        <Headline {...headline2} />
        <HeadlineStyled>{headline3}</HeadlineStyled>
        <Label dangerouslySetInnerHTML={{ __html: label }} />
        <ImgContainerBottom>
          <DynamicImageStyledBottom
            src="upsell/app.png"
            alt="Welcome baby app"
          />
        </ImgContainerBottom>
      </SecondSubTextContainer>
      <PlanSectionComponent
        {...planSection2}
        cardPadding="1rem 1rem 4rem"
        cardPaddingMobile="2rem 1rem 3rem"
      />
      <UpsellReviewSection {...reviewsSection} />
    </>
  );
};

export default AppSubscription;
