import React, { FC } from 'react';
import styled from 'styled-components';
import CardContainer from './CardContainer';
import TickIcon from 'assets/icons/tick-circle.svg';

import { Text } from 'components';
import { smMobile, tablet } from 'styles/breakpoints';
import { ImageWithContainer } from '..';

interface ListProps {
  title: string;
  list: string[];
  isNumberedList?: boolean;
  padding?: string;
  cardBgColor?: string;
  maxWidth?: string;
  cardHasBorder?: boolean;
  cardPadding?: string;
  cardPaddingMobile?: string;
}

const Container = styled.div<{ padding: string }>`
  width: 100%;
  display: flex;
  justify-content: center;
  background: #f0f0f0;
  padding: 0 1rem !important;
`;

const ContentContainer = styled.div<{ maxWidth?: string }>`
  width: 100%;
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : '30.5rem')};
`;

const Title = styled(Text)`
  color: #000;
  text-align: center;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2rem;
  padding-bottom: 1rem;
  @media ${tablet} {
    font-size: 1.5rem;
    line-height: 2rem;
  }
`;

const ListItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 0.75rem;
  max-width: 36.7rem;
`;

const Item = styled(Text)`
  color: #28293d;
  font-size: 1rem;
  line-height: 150%;
  letter-spacing: -0.011rem;
  padding-left: 1rem;
`;

const CardContainerStyled = styled(CardContainer)<{
  cardBgColor?: string;
  padding?: string;
  paddingMobile?: string;
}>`
  background-color: ${({ cardBgColor }) =>
    cardBgColor ? cardBgColor : '#fff'};
  border: 1px solid #f0f0f0;
  padding: ${({ padding }) => (padding ? padding : '1.5rem')};
  margin: 0;

  @media ${tablet} {
    padding: ${({ paddingMobile }) =>
      paddingMobile ? paddingMobile : '1.5rem 1rem'};
  }
`;

const Circle = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 3.8265rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #05a56f;
`;

const Number = styled(Text)`
  color: #fff;
  font-size: 0.91838rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
`;

const IconContainer = styled.div`
  width: 1.5rem;
  height: 1.5rem;
`;

const ListItem = ({
  item,
  isNumberedList,
  number,
}: {
  item: string;
  isNumberedList: boolean;
  number: number;
}) => (
  <ListItemContainer>
    <IconContainer>
      {isNumberedList ? (
        <Circle>
          <Number>{number}</Number>
        </Circle>
      ) : (
        <TickIcon width={24} height={24} fill="#05A56F" />
      )}
    </IconContainer>
    <Item dangerouslySetInnerHTML={{ __html: item }} />
  </ListItemContainer>
);

const List: FC<ListProps> = ({
  title,
  list,
  isNumberedList = false,
  padding = '0',
  cardBgColor,
  maxWidth,
  cardHasBorder = true,
  cardPadding,
  cardPaddingMobile,
  ...props
}) => {
  const renderListItem = (item: string, index: number) => (
    <ListItem
      item={item}
      key={index}
      isNumberedList={isNumberedList}
      number={index + 1}
    />
  );

  return (
    <Container padding={padding} {...props}>
      <ContentContainer maxWidth={maxWidth}>
        <CardContainerStyled
          cardBgColor={cardBgColor}
          padding={cardPadding}
          paddingMobile={cardPaddingMobile}
        >
          <Title>{title}</Title>
          {list?.map(renderListItem)}
        </CardContainerStyled>
      </ContentContainer>
    </Container>
  );
};

export default List;
